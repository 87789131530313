import React, { useState } from "react";
import Services from "../services";

const useGetTitle = () => {
  const [disclamerdata, setDisclamerdata] = useState();

  const TITLE_DISCLAMER = async (location, playmode) => {
    try {
      const query = {
        whitelabelId: 96,
        country: location,
        playmode,
        lang: localStorage.getItem("i18nextLng"),
        // lang: "ja-jp",
      };
      const queryParams = new URLSearchParams(query).toString();

      const response = await Services.Home.TITLE_DISCLAMER(queryParams);
     
      setDisclamerdata(response?.data[0]);
    } catch (error) {
      console.error(error);
    }
  };
  return { disclamerdata, TITLE_DISCLAMER };
};

export default useGetTitle;
