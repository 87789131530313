import React, { useEffect, useState } from "react";
import logo1 from "../../assets/thumb_ace-lucky-casino-1505812359-188x79@2x-1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Services from "../../services";
import useGetTitle from "../../hooks/useGetTitle";


import {  useDispatch } from 'react-redux';


import { increment } from '../../Redux/features/counterSlice';
export default function Header({ data }) {
  const { t, i18n } = useTranslation();
  const { disclamerdata, TITLE_DISCLAMER } = useGetTitle();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [location, setLocation] = useState("");
  const dispatch =useDispatch()
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      changeLanguage(localStorage.getItem("i18nextLng"));
      setSelectedLanguage(localStorage.getItem("i18nextLng"));
    } else {
      changeLanguage("en-gb");
    }
  }, []);

  const LOCATION = async () => {
    try {
      const location = await Services.Home.LOCATION();
      setLocation(location.data.CountryCode);

      localStorage.setItem("Location", location.data.CountryCode);
      dispatch(increment())

    } catch (error) {
      console.error(error);
      console.log("location", error);
    }
  };
  const [toggle, settoggle] = useState(false);

  useEffect(() => {
    LOCATION();
  }, []);

  return (
    <div>
      <header
        className="fixed top-0 lg:relative w-full z-40 bg-gray-900"
        data-v-3a9cbec9
      >
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          data-v-3a9cbec9
        >
          <div className="flex h-16 items-center" data-v-3a9cbec9>
            <div className="flex items-center" data-v-3a9cbec9>
              {
                toggle ? (
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-8 w-auto mr-3 cursor-pointer text-white"
                    onClick={() => settoggle(!toggle)}
                    data-v-3a9cbec9
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                ) : (
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-8 w-auto mr-3 cursor-pointer text-white"
                    onClick={() => settoggle(!toggle)}
                    data-v-3a9cbec9
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )
              }

              <Link
                aria-current="page"
                to="/"
                className="router-link-active "
                data-v-3a9cbec9
              >
                <span className="sr-only" data-v-3a9cbec9>
                  {t("AceLuckyCasino")}
                </span>
                <img
                  className="h-12 w-auto"
                  src={logo1}
                  alt="Ace Lucky Casino logo"
                  data-v-3a9cbec9
                />
              </Link>
            </div>
            <div
              className="hidden lg:ml-8 lg:block lg:self-stretch"
              data-v-3a9cbec9
            >
              <div className="flex h-full space-x-8" data-v-3a9cbec9>
              
                <div className="relative flex" data-v-3a9cbec9>
                  <Link
                    to="/"
                    className="border-transparent text-slate-50 hover:underline hover:underline-offset-[20px] hover:decoration-4 hover:text-cyan-300  relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                    data-v-3a9cbec9
                  >
                    {t("casino")}
                  </Link>
                </div>
                <div className="relative flex" data-v-3a9cbec9>
                  <Link
                    to="/slots"
                    className="border-transparent text-slate-50 hover:underline hover:underline-offset-[20px] hover:decoration-4 hover:text-cyan-300  relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                    data-v-3a9cbec9
                  >
                    {t("slots")}
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="flex flex-1 items-center justify-end space-x-4"
              data-v-3a9cbec9
            >

              <Link
                to="https://www.aceluckycasino.com/#m/login"
                className="hidden sm:block text-sm font-medium text-slate-50 hover:text-blue-700"
                data-v-3a9cbec9
              >
                {t("signin")}
              </Link>
              <span
                className="hidden sm:block h-6 w-px bg-gray-800"
                aria-hidden="true"
                data-v-3a9cbec9
              />
              <Link
                to="https://www.aceluckycasino.com/?dynamic=organic&btag=organic&a=default/#m/registration"
                className="rounded-sm bg-custom-blue px-1.5 py-1 text-sm font-semibold text-black shadow-sm hover:bg-custom-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                data-v-3a9cbec9
              >
                {t("signup")}
              </Link>
            </div>

            <div className="ml-auto flex items-center" data-v-3a9cbec9>
              <div className="ml-2 hidden sm:flex" data-v-3a9cbec9>
                <select
                  className="relative w-full cursor-default rounded-sm bg-gray-900 p-1 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-800 focus:outline-none focus:ring-1 focus:ring-gray-800 sm:text-sm"
                  data-v-3a9cbec9
                  value={selectedLanguage}
                  onChange={(e) => {

                    setSelectedLanguage(e.target.value);
                    changeLanguage(e.target.value);
                    localStorage.setItem("i18nextLng", e.target.value);
                    setTimeout(function () {
                      window.location.reload();
                    }, 2000);

                    data(e.target.value);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <option value="en-gb">EN</option>
                  <option value="fr-ca">FR</option>
                  <option value="es-la">ES</option>
                  <option value="pt-pt">PT</option>
                  <option value="ja-jp">JA</option>
                </select>
              </div>
              <Link
                className="flex items-center text-slate-50 hover:text-cyan-300 ml-2"
              >
                <img
                  alt=""
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${location}.svg`}
                  className="block h-auto w-5 flex-shrink-0"
                />
                <span className="ml-2 block text-sm font-medium">
                  {location}
                </span>
              </Link>
            </div>
          </div>
        </nav>
        <div
          className={` h-screen overflow-y-auto no-scrollbar fixed inset-y-0 top-16 ${toggle ? "left-[0]" : "left-[-100%]"
            } w-60 bg-gray-900 border-t border-gray-800 duration-500 ease-in-out`}
          data-v-f3db4835
        >
          <div className="mt-3 px-6 flex items-center w-full" data-v-f3db4835>
            <div
              className="flex flex-1 items-center justify-start space-x-4"
              data-v-f3db4835
            >
              <Link
                to="https://www.aceluckycasino.com/#m/login"
                className="text-sm font-medium text-slate-50 hover:text-blue-700"
                data-v-f3db4835
              >
                {t("signin")}
              </Link>
              <span
                className="h-6 w-px bg-gray-800"
                aria-hidden="true"
                data-v-f3db4835
              />
              <Link
                to="https://www.aceluckycasino.com/?dynamic=organic&btag=organic&a=default/#m/registration"
                className="mr-auto rounded-sm bg-custom-blue px-1.5 py-1 text-sm font-semibold text-black-500 shadow-sm hover:bg-custom-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                data-v-f3db4835
              >
                {t("signup")}
              </Link>
            </div>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="h-4 ml-auto cursor-pointer text-white "
              onClick={() => {
                settoggle(false)
              }}
              data-v-f3db4835
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="h-full space-y-4" data-v-f3db4835>
            <hr className="border-t mt-3 border-gray-800" data-v-f3db4835 />
            <div
              className="text-xs uppercase font-bold text-gray-500 mt-4 px-6"
              data-v-f3db4835
            >
              {t("AceLuckyCasino")}
            </div>
         
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="/"
                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-2 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {t("casino")}
              </Link>
            </div>
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="/slots"
                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-2 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {" "}
                {t("slots")}
              </Link>
            </div>
            {/*]*/}
            <hr className="border-t border-gray-800" data-v-f3db4835 />
            <div
              className="text-xs uppercase font-bold text-gray-500 px-6"
              data-v-f3db4835
            >
              {t("other")}
            </div>
            {/*[*/}
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="https://www.aceluckycasino.com/promotions"
                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-3 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {t("promotions")}
              </Link>
            </div>
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="https://www.aceluckycasino.com/about-us-info"
                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-3 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {t("aboutUs")}
              </Link>
            </div>
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="https://www.aceluckycasino.com/terms-conditions-info"
                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-3 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {t("terms&amp;Conditions")}
              </Link>
            </div>
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="https://www.aceluckycasino.com/privacy-policy-info"
                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-3 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {t("privacyPolicy")}
              </Link>
            </div>
            <div className="relative mb-2 mx-6" data-v-f3db4835>
              <Link
                to="https://rubyaffiliates.com/"

                className="border-transparent text-slate-50 hover:text-blue-600 hover:border-blue-600 border-b-2 relative z-10 -mb-px pt-px py-3 text-sm font-medium transition-colors duration-200 ease-out"
                data-v-f3db4835
              >
                {t("Affiliates")}
              </Link>
            </div>
            <hr className="border-t mt-3 border-gray-800" data-v-f3db4835 />

            <div className="mt-3 mx-4 flex items-center" data-v-f3db4835>
              <div
                className="flex flex-1 items-center justify-start space-x-4"
                data-v-f3db4835
              >
                <select
                  className="relative w-full cursor-default rounded-sm bg-gray-900 p-1 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-800 focus:outline-none focus:ring-1 focus:ring-gray-800 sm:text-sm"
                  data-v-f3db4835
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e.target.value);
                    changeLanguage(e.target.value);
                    localStorage.setItem("i18nextLng", e.target.value);
                    setTimeout(function () {
                      window.location.reload();
                    }, 2000);

                    data(e.target.value);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <option value="en-gb">EN</option>
                  <option value="fr-ca">FR</option>
                  <option value="es-la">ES</option>
                  <option value="pt-pt">PT</option>
                </select>
                <Link
                  className="flex items-center text-slate-50 hover:text-blue-700 pr-6"
                  data-v-f3db4835
                >
                  <img
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${location}.svg`}
                    alt=""
                    className="block h-auto w-5 flex-shrink-0"
                  />
                  <span className="ml-2 block text-sm font-medium">
                    {location}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
