import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Services from "../../services";
import DomPurify from "dompurify";

export default function CardSlider() {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const language = localStorage.getItem("i18nextLng");

  const PAYMENTS = async () => {
    try {
      const query = {
        whitelabelId: 96,
        country: localStorage.getItem("Location"),
        Code: "footericon",
        lang: language,
      };
      const queryParams = new URLSearchParams(query).toString();

      const response = await Services.deposits.DEPOSITS(queryParams);
      if (response) {
        const sanitizedData = DomPurify.sanitize(
          response?.data[0]?.Html
        ).replace(/\r?\n/g, "");
        const paymentMethodIcons = extractPaymentMethodIcons(sanitizedData);
        setData(paymentMethodIcons);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const extractPaymentMethodIcons = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const listItems = doc.querySelectorAll("ul.footer_icons li");

    const paymentMethodIcons = Array.from(listItems).filter((item) =>
      item.querySelector("a[title='Payment Methods']")
    );

    return paymentMethodIcons.map((item) => item.outerHTML);
  };

  useEffect(() => {
    PAYMENTS();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setReload(true);
    }
  }, [data]);

  const options = {
    items: 5,
    loop: true,
    speed: 250,
    autoplay: true,
    dots:false,
   responsive : {
     
      0 : {
        items : 3,
          
      },
     
      480 : {
        items : 3,
         
      },
     
      768 : {
        items : 4,
        
      },
      1500 : {
        items : 5,
        
      }
  }
  
  };

  return (
    <div>
      <div>
        <div className="bg-white">
          <div className="mx-auto max-w-7xl py-3 cursor-grab" >
            {reload && (
              <OwlCarousel className="owl-theme"style={{marginTop:"-20px"}} {...options}>
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="item carousel__slide carousel__slide--sliding"
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}