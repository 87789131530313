/* eslint-disable import/no-anonymous-default-export */
export default {
  // TITLE_DISCLAMER: () => ` https://content.progressplay.net/api23/api/PromotionsInfo?whitelabelId=211&country=IN&playmode=sport`,
  // SPORTS_DATA: () => `https://content.progressplay.net/api23/api/game?whitelabelId=10&country=ZA`,
  // LOCATION: () => `https://betneptune.applatus.com/whois.php`,
  LOCATION: () => `https://casinex.com/whois.php`,
 
  TITLE_DISCLAMER: (query) => ` https://content.progressplay.net/api23/api/PromotionsInfo?${query}`,
  SPORTS_DATA: (query) => `https://content.progressplay.net/api23/api/game?${query}`,
  PAGES_DATA: (query) => `https://content.progressplay.net/api23/api/InfoContent?${query}`,


};